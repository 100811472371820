import React, { Component } from "react";
import "./Modal.css";
import {  phonecode, fetchRegister } from '../util';

class FormularioSignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      apellidos: "",
      correo: "",
      password: "",
      phone: ""
    };
  }


  handleClick = async event => {
    event.preventDefault();
    let formulario = document.getElementById("formularioSignIn");
    let nombre = document.getElementById("idnombre2").value;
    let apellidos = document.getElementById("idapellidos2").value;
    let correo = document.getElementById("idcorreo2").value;
    let phone = document.getElementById("idphone2").value;
    let pass = document.getElementById("idpass2").value;
    let f_country = document.getElementById("idpais").value;

    let carganding = document.getElementById("Cargando2");
    let mensajeError2 = document.getElementById("mensajeError2");
    if (formulario.checkValidity()) {
      carganding.style.display = "flex";
        const phoneCountryCode =  await phonecode(f_country);
        const formRegisters = {
          firstName: nombre,
          lastName: apellidos,
          email: correo,
          password: pass,
          phoneNumber: phone,
          phoneCode: phoneCountryCode,
          country: f_country,
          page: "https://solutraders.net/",
          brand: "Solutraders register",
        };

        if (phoneCountryCode) {
          fetchRegister(formRegisters, carganding);
        }
    } else {
      mensajeError2.innerHTML = "Rellena los campos correctamente";
    }
  };

  handleNombre = event => {
    const nombre = event.target.value;
    this.setState({ nombre: nombre });
  };

  handleApellidos = event => {
    const apellidos = event.target.value;
    this.setState({ apellidos: apellidos });
  };

  handleCorreo = event => {
    const correo = event.target.value;
    this.setState({ correo: correo });
  };

  handlePass = event => {
    const pass = event.target.value;
    this.setState({ password: pass });
  };

  handlePhone = event => {
    const phone = event.target.value;
    this.setState({ phone: phone });
  };

  render() {
    return (
      <form
        id="formularioSignIn"
        onSubmit={e => {
          this.handleClick(e);
        }}
      >
        <div className="formularioSignInSub">
          {this.props.texto.title_registrarse}
        </div>
        <label>{this.props.texto.name}</label>
        <input
          type="text"
          name="Nombre"
          id="idnombre2"
          required
          value={this.state.nombre}
          onChange={this.handleNombre}
        ></input>
        <label>{this.props.texto.lastname}</label>
        <input
          type="text"
          name="Apellido"
          id="idapellidos2"
          value={this.state.apellidos}
          onChange={this.handleApellidos}
        ></input>
        <label>{this.props.texto.label1}</label>
        <input
          type="email"
          name="Correo"
          id="idcorreo2"
          value={this.state.correo}
          onChange={this.handleCorreo}
          pattern="[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]{1,}@[a-zA-Z0-9-]{2,}[.]{1}[a-zA-Z]{2,}"
        ></input>
        <label>{this.props.texto.phone}</label>
        <input
          type="text"
          name="Phone"
          id="idphone2"
          value={this.state.phone}
          onChange={this.handlePhone}
          minLength="8"
          maxLength="12"
        ></input>
        <label>{this.props.texto.pais}</label>
        <select id="idpais">
          <option value="MX"> México </option>
          <option value="CL">Chile </option>
          <option value="PE"> Peru </option>
          <option value="CR">Costa Rica </option>
          <option value="PA"> Panama </option>
        </select>
        <label>{this.props.texto.label2}</label>
        <input
          type="password"
          name="pass"
          id="idpass2"
          value={this.state.password}
          onChange={this.handlePass}
          pattern="^(?=.*[a-zA-Z])(?=\w*[0-9])[^_\W]{6,12}$"
        ></input>

        <button type="submit" className="boton botonPrincipal botonContacto">
          {this.props.texto.submit}
        </button>
        <br />
      </form>
    );
  }
}

export default FormularioSignIn;
