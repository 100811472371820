import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Inicio from "./components/inicio";
import Quienes from "./components/quienes";
import Contacto from "./components/contacto";
import Plataforma from "./components/plataforma";
import Politicas from "./components/politicas";
import { getCookie, setCookie } from "./components/util";

import "./App.css";

class App extends Component {
  render() {
    if (getCookie("fxlang") === null) {
      setCookie("fxlang", "ES", 20);
      window.location.reload();
    }
    return (
      <Router>
        <Route path="/" exact component={Inicio}></Route>
        <Route path="/quienes" component={Quienes}></Route>
        <Route path="/contacto" component={Contacto}></Route>
        <Route path="/plataforma" component={Plataforma}></Route>
        <Route path="/politicas" component={Politicas}></Route>
      </Router>
    );
  }
}
export default App;
