import React, { Component } from "react";

import "./Modal.css";
//import axios from "axios";

class Formulario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      correo: "",
      password: "",
      msg: ""
    };
  }

  getCookie = () => {
    var nameEQ = "Idioma=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return "es";
  };

  handleClick = event => {
    event.preventDefault();
    let correo = document.getElementById("idcorreo").value;
    let password = document.getElementById("idpass").value;
    let carganding = document.getElementById("Cargando");
    let mensajeError = document.getElementById("mensajeError");
    let idioma = this.getCookie();

    if (password === "" || correo === "") {
      if (idioma === "es") {
        mensajeError.innerHTML = "* Favor de rellenar los campos";
      } else {
        mensajeError.innerHTML = "* Please complete the fields";
      }
    } else {
      const that = this;
      carganding.style.display = "flex";
      fetch("https://webservicesnt.org:4443/insert/AutoLogin", {
        method: "POST",
        body: JSON.stringify({
          user: correo,
          pass: password
        }),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(function (response) {
          console.log(response);
          if (response.result === 1) {
            window.location.href = response.data.session_url;
          } else {
            carganding.style.display = "none";
            mensajeError.innerHTML = that.props.texto[response.error];
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  handlePass = event => {
    const pass = event.target.value;
    this.setState({ password: pass });
  };

  handleCorreo = event => {
    const correo = event.target.value;
    this.setState({ correo: correo });
  };

  render() {
    return (
      <form
        onSubmit={e => {
          this.handleClick(e);
        }}
      >
        <label>{this.props.texto.label1}</label>
        <input
          type="text"
          name="correo"
          id="idcorreo"
          required
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          value={this.state.correo}
          onChange={this.handleCorreo}
        ></input>
        <label>{this.props.texto.label2}</label>
        <input
          type="password"
          name="pass"
          id="idpass"
          value={this.state.password}
          onChange={this.handlePass}
        ></input>

        <button type="submit" className="boton botonPrincipal botonContacto">
          {this.props.texto.submit}
        </button>
        <label id="mensajeError"></label>
      </form>
    );
  }
}

export default Formulario;
