import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/images/logo.svg";
import imagenCompu from "../assets/images/compu.png";
import Footer from "./footer";
import $ from "jquery";
import "./plataforma/plataforma.css";
import habilidadUnoImagen from "../assets/images/habilidadUno.svg";
import habilidadDosImagen from "../assets/images/habilidadDos2.svg";
import habilidadTresImagen from "../assets/images/habilidadTres3.svg";
import laptop from "../assets/images/laptop.png";
import { Link } from "react-router-dom";
import callCenter from "../assets/images/callCenter.webp";
import Modal from "./modals/ModalSignIn";
import { setCookie, getCookie, getBoot } from "./util";

class Contacto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boot: getBoot()[getCookie("fxlang")],
    };
  }

  changeLang(new_lang) {
    setCookie("fxlang", new_lang, 20);
    this.setState({ boot: getBoot()[new_lang] });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $(window).scroll(function () {
      if ($("#menu").offset().top > 50) {
        $("#menu").removeClass("navInicio");
      } else {
        $("#menu").removeClass("navInicio");
      }
    });
  }

  render() {
    return (
      <>
        <header>
          {/* #SECTION Barra de navegacion */}
          <nav
            id="menu"
            className="fixed-top navbar navbar-expand-lg contenedorNav navSecundaria"
          >
            <Link className="navbar-brand" to="/">
              <img src={logo} width="300" alt="" />
            </Link>
            <div className="contenedorModalIniciarSesionDos">
              <Modal
                txt={this.state.boot.nav}
                tipo="1"
                Texto={this.state.boot.nav.cuenta}
              />
              <Modal
                txt={this.state.boot.nav}
                tipo="2"
                Texto={this.state.boot.nav.sesion}
              />
              <select
                className="idiomaNav"
                name="lenguaje"
                id="selectorLenguaje1"
                defaultValue={getCookie("fxlang")}
                onChange={(e) =>
                  this.changeLang(
                    document.getElementById("selectorLenguaje1").value
                  )
                }
              >
                <option value="ES">ES</option>
                <option value="EN">EN</option>
              </select>
            </div>
            <div className="contenedorIdiomaMovil">
              <select
                className="idiomaNav"
                name="lenguaje"
                id="selectorLenguaje2"
                defaultValue={getCookie("fxlang")}
                onChange={(e) =>
                  this.changeLang(
                    document.getElementById("selectorLenguaje2").value
                  )
                }
              >
                <option value="ES">ES</option>
                <option value="EN">EN</option>
              </select>
            </div>

            <button
              onClick={this.pintarNegro}
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <FontAwesomeIcon className="iconNavToggle" icon={faBars} />
            </button>
            <div className="collapse navbar-collapse listaNav" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/quienes">
                    {this.state.boot.nav.quienes}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contacto">
                    {this.state.boot.nav.contacto}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link enlaceActivo" to="/plataforma">
                    {this.state.boot.nav.plataforma}
                  </Link>
                </li>
              </ul>
              <Modal
                txt={this.state.boot.nav}
                tipo="1"
                Texto={this.state.boot.nav.cuenta}
              />
              <Modal
                txt={this.state.boot.nav}
                tipo="2"
                Texto={this.state.boot.nav.sesion}
              />
              <select
                className="idiomaNav"
                name="lenguaje"
                id="selectorLenguaje"
                defaultValue={getCookie("fxlang")}
                onChange={(e) =>
                  this.changeLang(
                    document.getElementById("selectorLenguaje").value
                  )
                }
              >
                <option value="ES">ES</option>
                <option value="EN">EN</option>
              </select>
            </div>
          </nav>
          <section className="cabeceraQuienes"></section>
        </header>
        <section className="contenedorInvierte">
          <div className="invierte">
            <div className="liderMundial">
              <div className="liderMundialTitulo">
                {this.state.boot.Plataforma_texto1}
              </div>
              <p>{this.state.boot.Plataforma_texto1_1}</p>
              <Modal
                txt={this.state.boot.nav}
                tipo="3"
                Texto={this.state.boot.boton_2}
              />
            </div>
            <div className="imagenCompu">
              <img src={imagenCompu} alt="Imagen Computadora" />
            </div>
          </div>
        </section>
        <section className="contenedorCombinacion">
          <div className="contenedorCombinacionTitulo">
            {this.state.boot.Plataforma_texto2}
          </div>
          <div className="contenedorHabilidades">
            <div className="habilidadUno">
              <img src={habilidadUnoImagen} alt="Habilidad Uno" />
              <div className="habilidadSubtitulo">
                {this.state.boot.Plataforma_texto3}
              </div>
              <p>{this.state.boot.Plataforma_texto3_1}</p>
            </div>
            <div className="habilidadDos">
              <img src={habilidadDosImagen} alt="Habilidad Dos" />
              <div className="habilidadSubtitulo">
                {this.state.boot.Plataforma_texto4}
              </div>
              <p>{this.state.boot.Plataforma_texto4_1}</p>
            </div>
            <div className="habilidadTres">
              <img src={habilidadTresImagen} alt="Habilidad Tres" />
              <div className="habilidadSubtitulo">
                {this.state.boot.Plataforma_texto5}
              </div>
              <p>{this.state.boot.Plataforma_texto5_1}</p>
            </div>
          </div>
        </section>
        <section className="contenedorTipoCuentas">
          <div className="tipoCuentas">
            <div className="cuentasBoton">
              <div className="cuentasBotonTitulo">
                {this.state.boot.Plataforma_texto6}
              </div>
              <Modal
                txt={this.state.boot.nav}
                tipo="3"
                Texto={this.state.boot.boton_2}
              />
            </div>
            <div className="explicacionCuentas">
              <div className="cuentaInicial">
                <div>Inicial</div>
                <p>250 usd</p>
              </div>
              <div className="cuentaBasic">
                <div>Basic</div>
                <p>5.000 usd</p>
              </div>
              <div className="cuentaPro">
                <div>Pro</div>
                <p>25.000 usd</p>
              </div>
              <div className="cuentaExpert">
                <div>Traders</div>
                <p>100.000 usd</p>
              </div>
              <div className="cuentaBlack">
                <div>Black</div>
                <p>250.000 usd</p>
              </div>
            </div>
          </div>
        </section>
        <section className="contenedorMercado">
          <div className="mercado">
            <div className="mercadoImagen">
              <img src={laptop} alt="Laptop" />
            </div>
            <div className="mercadoTexto">
              <div className="mercadoTextoTitulo">
                {this.state.boot.Plataforma_texto7}
              </div>
              <p>{this.state.boot.Plataforma_texto7_1}</p>
              <Modal
                txt={this.state.boot.nav}
                tipo="4"
                Texto={this.state.boot.boton_2}
              />
            </div>
          </div>
          <div className="dudas">
            <div className="dudasTexto">
              <div className="dudasTextoTitulo">
                {this.state.boot.Plataforma_texto8}
              </div>
              <p>{this.state.boot.Plataforma_texto8_1}</p>
              <button className="boton botonDudas">
                <Link to="/contacto">
                  <span style={{ color: "#000000" }}>
                    {this.state.boot.boton_3}
                  </span>
                </Link>
              </button>
            </div>
            <div className="dudasImagen">
              <img src={callCenter} alt="call Center" />
            </div>
          </div>
        </section>
        <div className="contenedorBotonesMovil">
          <Modal
            txt={this.state.boot.nav}
            tipo="1"
            Texto={this.state.boot.nav.cuenta}
          />
          <Modal
            txt={this.state.boot.nav}
            tipo="2"
            Texto={this.state.boot.nav.sesion}
          />
        </div>
        <Footer boot={this.state.boot} />
      </>
    );
  }
}
export default Contacto;
