import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faEnvelope,
  faPhoneAlt
} from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/images/logo.svg";
import Footer from "./footer";
import "./contacto/contacto.css";
import { Link } from "react-router-dom";
import $ from "jquery";
import imagenEdificio from "../assets/images/contactoImagen.png";
import { setCookie, getCookie, getBoot } from "./util";
import mujer from "../assets/images/contactoImagenMujer.png";
import Modal from "./modals/ModalSignIn";

class Plataforma extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boot: getBoot()[getCookie("fxlang")]
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $(window).scroll(function() {
      if ($("#menu").offset().top > 50) {
        $("#menu").removeClass("navInicio");
      } else {
        $("#menu").removeClass("navInicio");
      }
    });
  }

  changeLang(new_lang) {
    setCookie("fxlang", new_lang, 20);
    this.setState({ boot: getBoot()[new_lang] });
  }

  render() {
    return (
      <>
        <header className="cabeceraInicio">
          {/* #SECTION Barra de navegacion */}
          <nav
            id="menu"
            className="fixed-top navbar navbar-expand-lg contenedorNav navSecundaria"
          >
            <Link className="navbar-brand" to="/">
              <img src={logo} width="200" alt="" />
            </Link>
            <div className="contenedorModalIniciarSesionDos">
              <Modal
                txt={this.state.boot.nav}
                tipo="1"
                Texto={this.state.boot.nav.cuenta}
              />
              <Modal
                txt={this.state.boot.nav}
                tipo="2"
                Texto={this.state.boot.nav.sesion}
              />
              <select
                className="idiomaNav"
                name="lenguaje"
                id="selectorLenguaje1"
                defaultValue={getCookie("fxlang")}
                onChange={e =>
                  this.changeLang(
                    document.getElementById("selectorLenguaje1").value
                  )
                }
              >
                <option value="ES">ES</option>
                <option value="EN">EN</option>
              </select>
            </div>
            <div className="contenedorIdiomaMovil">
              <select
                className="idiomaNav"
                name="lenguaje"
                id="selectorLenguaje2"
                defaultValue={getCookie("fxlang")}
                onChange={e =>
                  this.changeLang(
                    document.getElementById("selectorLenguaje2").value
                  )
                }
              >
                <option value="ES">ES</option>
                <option value="EN">EN</option>
              </select>
            </div>

            <button
              onClick={this.pintarNegro}
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <FontAwesomeIcon className="iconNavToggle" icon={faBars} />
            </button>
            <div className="collapse navbar-collapse listaNav" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/quienes">
                    {this.state.boot.nav.quienes}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link enlaceActivo" to="/contacto">
                    {this.state.boot.nav.contacto}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/plataforma">
                    {this.state.boot.nav.plataforma}
                  </Link>
                </li>
              </ul>
              <div className="contenedorModalIniciarSesion">
                <Modal
                  txt={this.state.boot.nav}
                  tipo="1"
                  Texto={this.state.boot.nav.cuenta}
                />
                <Modal
                  txt={this.state.boot.nav}
                  tipo="2"
                  Texto={this.state.boot.nav.sesion}
                />
                <select
                  className="idiomaNav"
                  name="lenguaje"
                  id="selectorLenguaje"
                  defaultValue={getCookie("fxlang")}
                  onChange={e =>
                    this.changeLang(
                      document.getElementById("selectorLenguaje").value
                    )
                  }
                >
                  <option value="ES">ES</option>
                  <option value="EN">EN</option>
                </select>
              </div>
            </div>
          </nav>
          <section className="contenedorContactanos">
            <div className="contacto">
              <div className="areaContacto">
                <div className="tituloContacto">
                  {this.state.boot.nav.contacto}
                </div>
                <p>{this.state.boot.Contactanos_texto1_1}</p>
                <span>
                  <FontAwesomeIcon
                    className="iconoContactanos"
                    icon={faEnvelope}
                  />
                  {this.state.boot.Contactanos_texto1_2}
                </span>
                <span>
                  <FontAwesomeIcon
                    className="iconoContactanos"
                    icon={faEnvelope}
                  />
                  {this.state.boot.Contactanos_texto1_3}
                </span>
                <span>
                  <FontAwesomeIcon
                    className="iconoContactanos"
                    icon={faPhoneAlt}
                  />
                  {this.state.boot.Contactanos_texto1_4}
                </span>
              </div>
              <div className="imagenContacto">
                <img src={imagenEdificio} alt="imagen de edificio" />
              </div>
            </div>
          </section>
        </header>
        <section className="contenedorRevolucion">
          <div className="revolucion">
            <div className="imagenRevolucion">
              <img src={mujer} alt="rueda" />
            </div>
            <div className="textoRevolucion">
              <div className="contenedorTextoRevolucion">
                <div className="textoRevolucionTitulo">
                  {this.state.boot.Contactanos_texto1_5}
                </div>
                <p>{this.state.boot.Contactanos_texto1_6}</p>
                <Modal
                  txt={this.state.boot.nav}
                  tipo="6"
                  Texto={this.state.boot.boton_2}
                />
              </div>
            </div>
          </div>
        </section>
        <div className="contenedorBotonesMovil">
          <Modal
            txt={this.state.boot.nav}
            tipo="1"
            Texto={this.state.boot.nav.cuenta}
          />
          <Modal
            txt={this.state.boot.nav}
            tipo="2"
            Texto={this.state.boot.nav.sesion}
          />
        </div>
        <Footer boot={this.state.boot} />
      </>
    );
  }
}
export default Plataforma;
