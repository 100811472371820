import React, { Component } from "react";
import Slider from "react-slick";
import Amazon from "../../assets/images/marcas/Amazon.png";
import Disney from "../../assets/images/marcas/Disney.png";
import Google from "../../assets/images/marcas/Google.png";
import Facebook from "../../assets/images/marcas/Facebook.png";
import CocaCola from "../../assets/images/marcas/Coca cola.png";
import Oracle from "../../assets/images/marcas/Oracle.png";
import Tesla from "../../assets/images/marcas/Tesla.png";
import Volkswagen from "../../assets/images/marcas/Volkswagen.png";
import PG from "../../assets/images/marcas/P&G.png";
import AMD from "../../assets/images/marcas/AMD.png";
import Walmart from "../../assets/images/marcas/Walmart.png";
import Toyota from "../../assets/images/marcas/Toyota.png";

import "./carrusel.css";

export default class SimpleSlider extends Component {
  state = {
    img_cards: {
      Amazon: Amazon,
      Disney: Disney,
      Google: Google,
      Facebook: Facebook,
      "Coca Cola": CocaCola,
      Oracle: Oracle,
      TESLA: Tesla,
      Volkswagen: Volkswagen,
      "P&G": PG,
      AMD: AMD,
      Walmart: Walmart,
      Toyota: Toyota,
    },
    infoTarjetas: [],
  };
  /*   componentDidMount() {
    const that = this;
    fetch("https://webservicesnt.org:4444/get/CurrenciesFeed?action=2")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        that.setState({
          infoTarjetas: myJson.data
        });
      });
  } */
  renderCards = () => {
    let cards = [];

    if (this.state.infoTarjetas.length > 0) {
      this.state.infoTarjetas.forEach((element) => {
        if (element.change_value > 0) {
          cards.push(
            <div className="sliderUnitario">
              <img
                src={this.state.img_cards[element.name]}
                alt={element.name}
              />
              <div className="precioElemento">
                <p>${element.price}</p>
              </div>
              <div className="nombreElemento">{element.name}</div>
              <div className="cambioElemento precioVerde">
                +{element.change_value}
              </div>
            </div>
          );
        } else {
          cards.push(
            <div className="sliderUnitario">
              <img
                src={this.state.img_cards[element.name]}
                alt={element.name}
              />
              <div className="precioElemento">
                <p>${element.price}</p>
              </div>
              <div className="nombreElemento">{element.name}</div>
              <div className="cambioElemento precioRojo">
                {element.change_value}
              </div>
            </div>
          );
        }
      });
    }
    return cards;
  };
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 800,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 420,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div>
        <Slider {...settings}>{this.renderCards()}</Slider>
      </div>
    );
  }
}
