/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unreachable */
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./Modal.css";
import Formulario from "./form";
import Logo from "../../../node_modules/svg-loaders/svg-css-loaders/three-dots.svg";
import FormularioSignUp from "./formSignUp";

const ModalSignUp = props => {
  const { className } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);

  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };

  /*  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }; */

  function Btn_asign(props) {
    const Head = props.btn;
    const Text = props.Texto;
    // console.log(props);
    switch (Head) {
      case "1":
        return (
          <>
            <button className="botonNav botonNavBlanco" onClick={toggleNested}>
              <span>{Text}</span>
            </button>
            <Modal
              id="modalregistrar"
              isOpen={nestedModal}
              toggle={toggleNested}
            >
              <ModalHeader toggle={toggleNested}></ModalHeader>
              <div id="Cargando2">
                <img src={Logo} alt="logo" />
              </div>
              <FormularioSignUp texto={props.all} />
            </Modal>
          </>
        );
        // eslint-disable-next-line no-unreachable
        break;
      case "2":
        return (
          <button className="botonNav botonNavVerde" onClick={() => window.open("https://my.solutraders.net/login")}>
            <span>{Text}</span>
          </button>
        );
        break;
      case "3":
        return (
          <button
            className="boton botonPrincipal botonContacto"
            onClick={toggle}
          >
            <span> {Text}</span>
          </button>
        );
        break;
      case "4":
        return (
          <button className="boton botonMercado" onClick={toggle}>
            <span> {Text}</span>
          </button>
        );
        break;
      case "5":
        return (
          <button className="boton botonDudas" onClick={toggle}>
            <span> {Text}</span>
          </button>
        );
        break;
      case "6":
        return (
          <button
            className="boton botonPrincipal botonContactanos"
            onClick={toggle}
          >
            <span> {Text}</span>
          </button>
        );
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Btn_asign btn={props.tipo} Texto={props.Texto} all={props.txt} />
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>
          <p>{props.txt.sesion}</p>
        </ModalHeader>
        <ModalBody>
          <div id="Cargando">
            <img src={Logo} alt="logo" />
          </div>
          <Formulario texto={props.txt} />
          <div className="innerModalBody">
            <p>{props.txt.label3} </p>

            <button className="boton botonDudas" onClick={toggleNested}>
              {props.txt.new_account}
            </button>
            <Modal
              id="modalregistrar"
              isOpen={nestedModal}
              toggle={toggleNested}
              onClosed={closeAll ? toggle : undefined}
            >
              <ModalHeader toggle={toggle}></ModalHeader>
              <div id="Cargando2">
                <img src={Logo} alt="logo" />
              </div>
              <FormularioSignUp texto={props.txt} />
            </Modal>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ModalSignUp;
