import React from 'react'

import "./whatsapp-icon.css";

import IconWhatsapp from "../../assets/images/icon-whatsapp.png";

export default function WhatsappIcon() {
  return (
    <a href="https://wa.me/+5215539685826" target="_blank" className="btn-social" rel="noopener noreferrer">
      <img src={IconWhatsapp} alt="" className="btn-social__icon" />
    </a>
  );
}
