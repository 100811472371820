import React, { Component } from "react";
import { Link } from "react-router-dom";
/* import Yandex from "../assets/images/pagos/asset6.webp";
import AlfaBank from "../assets/images/pagos/asset7.webp";
import Maestro from "../assets/images/pagos/asset8.webp";
import Mastercard from "../assets/images/pagos/asset9.webp";
import Advcash from "../assets/images/pagos/asset10.webp";
import American from "../assets/images/pagos/asset11.webp";
import Sofort from "../assets/images/pagos/asset12.webp";
import Secure from "../assets/images/pagos/asset13.webp";
import Postepay from "../assets/images/pagos/asset14.webp";
import Qiwi from "../assets/images/pagos/asset15.webp";
import Upay from "../assets/images/pagos/asset16.webp";
import Verify from "../assets/images/pagos/asset17.webp";
import Wire from "../assets/images/pagos/asset18.webp";
import Masterpay from "../assets/images/pagos/asset19.webp"; */
import logo from "../assets/images/logo.svg";
/* import Telegram from "../assets/images/telegram.webp";
import Facebook from "../assets/images/facebook.webp"; */
/* import Pci from "../assets/images/pagos/asset1.webp";
import asset2 from "../assets/images/pagos/asset2.webp";
import RapidSSL from "../assets/images/pagos/asset3.webp";
import GeoTrust from "../assets/images/pagos/asset5.webp";
import GoDaddy from "../assets/images/pagos/asset4.webp";
import metatrader from "../assets/images/metatrader.webp"; */

import "./footer.css";
import useCompany from "../hooks/useCompany";

class footer extends Component {
  state = {
    company: ""
  };

  componentDidMount() {
    this.setState({
      company: useCompany(this.props.boot)
    });
  }

  render() {
    return (
      <>
        <footer>
          <div className="footerContainer">
            <div className="boxStyles">
              <div className="AddressContainer">
                <img src={logo} alt="logo" />
                <p>
                  <a
                    className="linkStyles"
                    href="mailto:contact@solutraders.net"
                  >
                    {this.props.boot.Footer_texto1_3}
                  </a>
                </p>
              </div>
              <div className="innerFooter">
                <div className="innerFooterNav">
                  <div className="legals">
                    <div className="footerQuienes">
                      <Link className="linkStyles" to="/quienes">
                        {this.props.boot.Footer_texto1_4}
                      </Link>
                    </div>
                    <div className="footerContacto">
                      <Link className="linkStyles" to="/contacto">
                        {" "}
                        {this.props.boot.Footer_texto1_5}
                      </Link>
                    </div>
                    <div>
                      <Link className="linkStyles" to="/politicas">
                        {this.props.boot.Footer_texto1_11}
                      </Link>
                    </div>
                    <div>
                      <Link className="linkStyles" to="/politicas?texto=8">
                        {this.props.boot.Footer_texto1_19}
                      </Link>
                    </div>
                    <div>
                      <Link className="linkStyles" to="/politicas?texto=2">
                        {this.props.boot.Footer_texto1_12}
                      </Link>
                    </div>
                    <div>
                      <Link className="linkStyles" to="/politicas?texto=3">
                        {this.props.boot.Footer_texto1_13}
                      </Link>
                    </div>
                    <div>
                      <Link className="linkStyles" to="/politicas?texto=4">
                        {this.props.boot.Footer_texto1_14}
                      </Link>
                    </div>
                    <div>
                      <Link className="linkStyles" to="/politicas?texto=5">
                        {this.props.boot.Footer_texto1_15}
                      </Link>
                    </div>
                    <div>
                      <Link className="linkStyles" to="/politicas?texto=6">
                        {this.props.boot.Footer_texto1_16}
                      </Link>
                    </div>
                    <div>
                      <Link className="linkStyles" to="/politicas?texto=7">
                        {this.props.boot.Footer_texto1_17}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright">
              <p>
                <span> {this.props.boot.Footer_texto1_20} </span>
                {this.props.boot.Footer_texto1_21}
                {" "}
                {this.state.company}
              </p>
            </div>
          </div>
        </footer>
      </>
    );
  }
}
export default footer;
