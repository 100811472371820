// import useGetCountry from "./useGetCountry";

export default function useCompany(t, country = "both") {
  // const { country: { code } } = useGetCountry();

  const MX = true;
  // const MX = code === "MX";
  // const CO = code === "CO";

  const directionMX = "Arquimedes 476, Miguel Hidalgo, Ciudad de Mexico";
  const companyMX = MX
    ? `${t.company} 2401 OS S.A. de C.V. ${directionMX}`
    : null;
  // const companyCO = CO ? `${t("company")} Marketing Group CO S.A.S` : null;

  return companyMX ?? "";
  // if (country === "both") return companyCO ?? companyMX ?? "";
  // if (country === "CO") return companyCO ?? "";
  // return "";
}