import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import Footer from "./footer";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./inicio/inicio.css";
import logo from "../assets/images/logo.svg";
import imgsegundasec from "../assets/images/imgsegsec.webp";
import fondos from "../assets/images/fondos.svg";
import seguridad from "../assets/images/seguridad.svg";
import asistencia from "../assets/images/asistencia.svg";
import testimonio1 from "../assets/images/testimonio3.webp";
import testimonio2 from "../assets/images/testimonio4.webp";
import testimonio3 from "../assets/images/testimonio5.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import callCenter from "../assets/images/callCenter.webp";
import comillas from "../assets/images/comillas.svg";
import rueda from "../assets/images/rueda.svg";
import Modal from "./modals/ModalSignIn";
import "react-slick/dist/react-slick.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carrusel from "./inicio/carrusel";
import { setCookie, getCookie, getBoot } from "./util";
import WhatsappIcon from "./contacto/whatsapp-icon";

class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boot: getBoot()[getCookie("fxlang")]
    };
  }

  changeLang(new_lang) {
    setCookie("fxlang", new_lang, 20);
    this.setState({ boot: getBoot()[new_lang] });
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    $(window).scroll(function() {
      if ($("#menu").offset().top > 50) {
        $("#menu").removeClass("navInicio");
      } else {
        $("#menu").addClass("navInicio");
      }
    });
  }

  pintarNegro() {
    $("#menu").toggleClass("fondoNegro");
  }
  render() {
    return (
      <>
        <header className="cabeceraInicio">
          <div className="contenedorInicial">
            <h1>
              Broker de Forex que ofrece los mejores rendimientos y ganancias
              operando para Latinoamérica
            </h1>
            <h2>
              Innovador sistema de Trading, Confiable, Simple y Seguro. Únase a
              miles que están Generando Ganancias con ésta Nueva Plataforma.
            </h2>
            <h2>Negocie CFD en Acciones, Índices, Fórex y Criptomonedas</h2>
            <h2>Tendencias del mercado</h2>
            <h2>Gráficos en tiempo real</h2>
            <h2>Noticias financieras</h2>
            <h2>Aprenda a operar en línea</h2>
            <h3>Reconocido a nivel mundial</h3>
            <h3>Soporte 24 horas</h3>
            <h3>Tome el control de sus finanzas</h3>
            <h2> Explore los mercados</h2>
            <h2>Negocie con confianza</h2>
            <h2> Negociación en la punta de sus dedos</h2>
            <p></p>
          </div>
          {/* #SECTION Barra de navegacion */}
          <WhatsappIcon />
          <nav
            id="menu"
            className="fixed-top navbar navbar-expand-lg contenedorNav navInicio"
          >
            <Link className="navbar-brand" to="/">
              <img src={logo} width="300" alt="" />
            </Link>
            <div className="contenedorModalIniciarSesionDos">
              <Modal
                txt={this.state.boot.nav}
                tipo="1"
                Texto={this.state.boot.nav.cuenta}
              />
              <Modal
                txt={this.state.boot.nav}
                tipo="2"
                Texto={this.state.boot.nav.sesion}
              />
              <select
                className="idiomaNav"
                name="lenguaje"
                id="selectorLenguaje1"
                defaultValue={getCookie("fxlang")}
                onChange={(e) =>
                  this.changeLang(
                    document.getElementById("selectorLenguaje1").value
                  )
                }
              >
                <option value="ES">ES</option>
                <option value="EN">EN</option>
              </select>
            </div>
            <div className="contenedorIdiomaMovil">
              <select
                className="idiomaNav"
                name="lenguaje"
                id="selectorLenguaje2"
                defaultValue={getCookie("fxlang")}
                onChange={(e) =>
                  this.changeLang(
                    document.getElementById("selectorLenguaje2").value
                  )
                }
              >
                <option value="ES">ES</option>
                <option value="EN">EN</option>
              </select>
            </div>

            <button
              onClick={this.pintarNegro}
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <FontAwesomeIcon className="iconNavToggle" icon={faBars} />
            </button>
            <div className="collapse navbar-collapse listaNav" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/quienes">
                    {this.state.boot.nav.quienes}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contacto">
                    {this.state.boot.nav.contacto}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/plataforma">
                    {this.state.boot.nav.plataforma}
                  </Link>
                </li>
              </ul>
              <div className="contenedorModalIniciarSesion">
                <Modal
                  txt={this.state.boot.nav}
                  tipo="1"
                  Texto={this.state.boot.nav.cuenta}
                />
                <Modal
                  txt={this.state.boot.nav}
                  tipo="2"
                  Texto={this.state.boot.nav.sesion}
                />
                <select
                  className="idiomaNav"
                  name="lenguaje"
                  id="selectorLenguaje"
                  defaultValue={getCookie("fxlang")}
                  onChange={(e) =>
                    this.changeLang(
                      document.getElementById("selectorLenguaje").value
                    )
                  }
                >
                  <option value="ES">ES</option>
                  <option value="EN">EN</option>
                </select>
              </div>
            </div>
          </nav>
          <section className="contenedorTecnologia">
            <img src={rueda} alt="rueda" className="ruedaInicio" />
            <div className="tecnologia">
              <h1>{this.state.boot.general_texto1}</h1>
              <Modal
                txt={this.state.boot.nav}
                tipo="3"
                Texto={this.state.boot.boton_1}
              />
            </div>
            <div className="carrusel">
              <Carrusel />
            </div>
          </section>
        </header>

        <section className="contenedorGanancias">
          <div className="ganancias">
            <div className="row">
              <div className="col-md-12 col-lg-6 textosGanancias">
                <div className="textosGananciasTitulo">
                  {this.state.boot.general_texto2}
                </div>
                <p>{this.state.boot.general_texto2_1}</p>
                <Modal
                  txt={this.state.boot.nav}
                  tipo="6"
                  Texto={this.state.boot.boton_2}
                />
              </div>
              <div className="col-md-12 col-lg-6 text-center">
                <img src={imgsegundasec} alt="imagen Segunda Seccion"></img>
              </div>
            </div>
          </div>
        </section>
        <section className="contenedorBeneficios">
          <div className=" contenedorTituloBeneficios">
            <h1 className="contenedorTituloBeneficiosTitulo">
              {this.state.boot.general_texto3}
            </h1>
            <div className="beneficios">
              <div className="beneficioUno">
                <img src={fondos} alt="fondos" />
                <div className="tituloBeneficio">
                  {this.state.boot.general_texto3_1}
                </div>
                <p>{this.state.boot.general_texto3_2}</p>
              </div>
              <div className="beneficioDos">
                <img src={seguridad} alt="seguridad" />
                <div className="tituloBeneficio">
                  {this.state.boot.general_texto3_3}
                </div>
                <p>{this.state.boot.general_texto3_4}</p>
              </div>
              <div className="beneficioTres">
                <img src={asistencia} alt="asistencia" />
                <div className="tituloBeneficio">
                  {this.state.boot.general_texto3_5}
                </div>
                <p>{this.state.boot.general_texto3_6}</p>
              </div>
            </div>
          </div>
        </section>
        <section className="contenedorTestimonios">
          <div className="testimonios">
            <div className="testimonioTitulo">
              {this.state.boot.general_texto4}
            </div>
            <div className="testimoniosSubtitulo">
              {this.state.boot.general_texto4_1}
            </div>
            <div className="gridTestimonios">
              <div className="testimonioUno">
                <div className="contenedorImagenTestimonio">
                  <img
                    src={testimonio1}
                    className="imagenTestimonio"
                    alt="Testimonio 1"
                  />
                  <img
                    src={comillas}
                    className="comillasTestimonio"
                    alt="comillas"
                    width="30px"
                  />
                </div>

                <div className="contenedorTextoTestimonio">
                  <p>{this.state.boot.general_texto4_2_1}</p>
                  <div className="rating">
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                  </div>
                  <div className="nombreTestimonio">
                    {this.state.boot.general_texto4_2_2}
                  </div>
                  <div className="tipoTestimonio">
                    {this.state.boot.general_texto4_2_3}
                  </div>
                </div>
              </div>
              <div className="testimonioDos">
                <div className="contenedorImagenTestimonio">
                  <img
                    src={testimonio2}
                    className="imagenTestimonio"
                    alt="Testimonio 1"
                  />
                  <img
                    src={comillas}
                    className="comillasTestimonio"
                    alt="comillas"
                    width="30px"
                  />
                </div>

                <div className="contenedorTextoTestimonio">
                  <p>{this.state.boot.general_texto4_3_1}</p>
                  <div className="rating">
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                  </div>
                  <div className="nombreTestimonio">
                    {this.state.boot.general_texto4_3_2}
                  </div>
                  <div className="tipoTestimonio">
                    {this.state.boot.general_texto4_3_3}
                  </div>
                </div>
              </div>
              <div className="testimonioTres">
                <div className="contenedorImagenTestimonio">
                  <img
                    src={testimonio3}
                    className="imagenTestimonio"
                    alt="Testimonio 2"
                  />
                  <img
                    src={comillas}
                    className="comillasTestimonio"
                    alt="comillas"
                    width="30px"
                  />
                </div>

                <div className="contenedorTextoTestimonio">
                  <p>{this.state.boot.general_texto4_4_1}</p>
                  <div className="rating">
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                  </div>
                  <div className="nombreTestimonio">
                    {this.state.boot.general_texto4_4_2}
                  </div>
                  <div className="tipoTestimonio">
                    {this.state.boot.general_texto4_4_3}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contenedorTipoCuentas">
          <div className="tipoCuentas">
            <div className="cuentasBoton">
              <div className="cuentasBotonTitulo">
                {this.state.boot.general_texto5}
              </div>
              <div className="botonCuentasEscritorio">
                <Modal
                  txt={this.state.boot.nav}
                  tipo="3"
                  Texto={this.state.boot.boton_2}
                />
              </div>
            </div>
            <div className="explicacionCuentas">
              <div className="cuentaInicial">
                <div>Inicial</div>
                <p>250 usd</p>
              </div>
              <div className="cuentaBasic">
                <div>Basic</div>
                <p>5.000 usd</p>
              </div>
              <div className="cuentaPro">
                <div>Pro</div>
                <p>25.000 usd</p>
              </div>
              <div className="cuentaTrader">
                <div>Solutraders</div>
                <p>100.000 usd</p>
              </div>
              <div className="cuentaBlack">
                <div>Black</div>
                <p>250.000 usd</p>
              </div>
            </div>
            <div className="botonCuentasMovil">
              <Modal
                txt={this.state.boot.nav}
                tipo="3"
                Texto={this.state.boot.boton_2}
              />
            </div>
          </div>
        </section>
        <section className="contenedorMercado">
          <div className="dudas">
            <div className="dudasTexto">
              <div className="dudasTextoTitulo">
                {this.state.boot.general_texto6}
              </div>
              <p>{this.state.boot.general_texto6_1}</p>
              <button className="boton botonDudas">
                <Link to="/contacto">
                  <span style={{ color: "#000000" }}>
                    {this.state.boot.boton_3}
                  </span>
                </Link>
              </button>
            </div>
            <div className="dudasImagen">
              <img src={callCenter} alt="call Center" />
            </div>
          </div>
        </section>
        <div className="contenedorBotonesMovil">
          <Modal
            txt={this.state.boot.nav}
            tipo="1"
            Texto={this.state.boot.nav.cuenta}
          />
          <Modal
            txt={this.state.boot.nav}
            tipo="2"
            Texto={this.state.boot.nav.sesion}
          />
        </div>
        <Footer boot={this.state.boot} />
      </>
    );
  }
}

export default Inicio;
