import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/images/logo.svg";
import "./quienes/quienes.css";
import imagenMision from "../assets/images/mision.svg";
import imagenVision from "../assets/images/vision.svg";
import imagenValores from "../assets/images/valores.svg";
import labor1 from "../assets/images/labor1.png";
import labor2 from "../assets/images/labor2.png";
import labor3 from "../assets/images/labor3.png";
import forex from "../assets/images/forex.svg";
import materias from "../assets/images/materiasPrimas.svg";
import indices from "../assets/images/indices.svg";
import bonos from "../assets/images/bonos.svg";
import acciones from "../assets/images/acciones.svg";
import combinaciones from "../assets/images/combinaciones.svg";
import etf from "../assets/images/etf.svg";
import Footer from "./footer";
import Modal from "./modals/ModalSignIn";
import $ from "jquery";
import { Link } from "react-router-dom";
import { setCookie, getCookie, getBoot } from "./util";

class Quienes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boot: getBoot()[getCookie("fxlang")]
    };
  }

  changeLang(new_lang) {
    setCookie("fxlang", new_lang, 20);
    this.setState({ boot: getBoot()[new_lang] });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $(window).scroll(function() {
      if ($("#menu").offset().top > 50) {
        $("#menu").removeClass("navInicio");
      } else {
        $("#menu").removeClass("navInicio");
      }
    });
  }

  render() {
    return (
      <>
        <header>
          {/* #SECTION Barra de navegacion */}
          <nav
            id="menu"
            className="fixed-top navbar navbar-expand-lg contenedorNav navSecundaria"
          >
            <Link className="navbar-brand" to="/">
              <img src={logo} width="200" alt="" />
            </Link>
            <div className="contenedorModalIniciarSesionDos">
              <Modal
                txt={this.state.boot.nav}
                tipo="1"
                Texto={this.state.boot.nav.cuenta}
              />
              <Modal
                txt={this.state.boot.nav}
                tipo="2"
                Texto={this.state.boot.nav.sesion}
              />
              <select
                className="idiomaNav"
                name="lenguaje"
                id="selectorLenguaje1"
                defaultValue={getCookie("fxlang")}
                onChange={e =>
                  this.changeLang(
                    document.getElementById("selectorLenguaje1").value
                  )
                }
              >
                <option value="ES">ES</option>
                <option value="EN">EN</option>
              </select>
            </div>
            <div className="contenedorIdiomaMovil">
              <select
                className="idiomaNav"
                name="lenguaje"
                id="selectorLenguaje2"
                defaultValue={getCookie("fxlang")}
                onChange={e =>
                  this.changeLang(
                    document.getElementById("selectorLenguaje2").value
                  )
                }
              >
                <option value="ES">ES</option>
                <option value="EN">EN</option>
              </select>
            </div>

            <button
              onClick={this.pintarNegro}
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <FontAwesomeIcon className="iconNavToggle" icon={faBars} />
            </button>
            <div className="collapse navbar-collapse listaNav" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link enlaceActivo" to="/quienes">
                    {this.state.boot.nav.quienes}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contacto">
                    {this.state.boot.nav.contacto}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/plataforma">
                    {this.state.boot.nav.plataforma}
                  </Link>
                </li>
              </ul>
              <div className="contenedorModalIniciarSesion">
                <Modal
                  txt={this.state.boot.nav}
                  tipo="1"
                  Texto={this.state.boot.nav.cuenta}
                />
                <Modal
                  txt={this.state.boot.nav}
                  tipo="2"
                  Texto={this.state.boot.nav.sesion}
                />
                <select
                  className="idiomaNav"
                  name="lenguaje"
                  id="selectorLenguaje"
                  defaultValue={getCookie("fxlang")}
                  onChange={e =>
                    this.changeLang(
                      document.getElementById("selectorLenguaje").value
                    )
                  }
                >
                  <option value="ES">ES</option>
                  <option value="EN">EN</option>
                </select>
              </div>
            </div>
          </nav>
          <section className="cabeceraQuienes">
            <div className="tituloQuienes">
              <div className="quienesSubtitulo">
                {this.state.boot.Quienes_texto}
              </div>
              <div className="tituloQuienesTitulo">
                {this.state.boot.Quienes_texto1}
              </div>
            </div>
            <div className="pilares">
              <div className="mision">
                <img src={imagenMision} alt="Mision" />
                <div className="pilaresSubtitulo">
                  {this.state.boot.Quienes_texto1_1}
                </div>
                <p>{this.state.boot.Quienes_texto1_1_2}</p>
              </div>
              <div className="vision">
                <img src={imagenVision} alt="Vision" />
                <div className="pilaresSubtitulo">
                  {this.state.boot.Quienes_texto1_2}
                </div>
                <p>{this.state.boot.Quienes_texto1_2_1}</p>
              </div>
              <div className="valores">
                <img src={imagenValores} alt="Valores" />
                <div className="pilaresSubtitulo">
                  {this.state.boot.Quienes_texto1_3}
                </div>
                <p>{this.state.boot.Quienes_texto1_3_1}</p>
              </div>
            </div>
          </section>
        </header>
        <section className="contenedorLabor">
          <div className="labor">
            <div className="parrafosLabor">
              <div className="parrafosLaborTitulo">
                {this.state.boot.Quienes_texto1_4}
              </div>
              <div className="parrafosLaborSubtitulo">
                {this.state.boot.Quienes_texto1_4_1}
              </div>
              <p>{this.state.boot.Quienes_texto1_4_2}</p>
              <div className="parrafosLaborSubtitulo">
                {this.state.boot.Quienes_texto1_4_3}
              </div>
              <p>{this.state.boot.Quienes_texto1_4_4}</p>
            </div>
            <div className="imagenesLabor">
              <img
                src={labor1}
                className="imagenLaborUno"
                alt="Nuestra Labor"
              />
              <img
                src={labor2}
                className="imagenLaborDos"
                alt="Nuestra Labor"
              />
              <img
                src={labor3}
                className="imagenLaborTres"
                alt="Nuestra Labor"
              />
            </div>
          </div>
        </section>
        <section className="contenedorPlataforma">
          <div className="plataforma">
            <div className="plataformaTitulo">
              {this.state.boot.Quienes_texto1_5}
            </div>
            <p>{this.state.boot.Quienes_texto1_5_1}</p>
            <div className="plataformaSubtitulo">
              {this.state.boot.Quienes_texto1_5_2}
            </div>
            <div className="contenedorIconosPlataforma">
              <span>
                <img src={forex} alt="forex" />
                <div className="contenedorIconosSub">
                  {this.state.boot.Quienes_texto1_5_2_1}
                </div>
              </span>
              <span>
                <img src={materias} alt="materias" />
                <div className="contenedorIconosSub">
                  {this.state.boot.Quienes_texto1_5_2_2}
                </div>
              </span>
              <span>
                <img src={indices} alt="indices" />
                <div className="contenedorIconosSub">
                  {this.state.boot.Quienes_texto1_5_2_3}
                </div>
              </span>
              <span>
                <img src={bonos} alt="bonos" />
                <div className="contenedorIconosSub">
                  {" "}
                  {this.state.boot.Quienes_texto1_5_2_4}
                </div>
              </span>
              <span>
                <img src={acciones} alt="acciones" />
                <div className="contenedorIconosSub">
                  {this.state.boot.Quienes_texto1_5_2_5}
                </div>
              </span>
              <span>
                <img src={combinaciones} alt="combinaciones" />
                <div className="contenedorIconosSub">
                  {this.state.boot.Quienes_texto1_5_2_6}
                </div>
              </span>
              <span>
                <img src={etf} alt="ETF" />
                <div className="contenedorIconosSub">
                  {this.state.boot.Quienes_texto1_5_2_7}
                </div>
              </span>
            </div>
          </div>
        </section>
        <section className="contenedorFormularioQuienes">
          <div className="formularioQuienes">
            <div className="contenedorBotonQuienes">
              <div className="contenedorBotonQuienesSubtitulo">
                {this.state.boot.Quienes_texto1_6}
              </div>
              {/* <Modal
                txt={this.state.nav}
                tipo="3"
                Texto={this.state.quienes.comienza}
              /> */}
            </div>
            <div className="contenedorSolicitud">
              <form action="" method="post">
                <label htmlFor="email">
                  {this.state.boot.Quienes_texto1_6_1}
                </label>

                <input type="email" id="email" name="email" required />
                <br />
                <label htmlFor="name">
                  {this.state.boot.Quienes_texto1_6_2}
                </label>

                <input type="text" id="name" name="name" />
                <br />
                <label htmlFor="apellidos">
                  {this.state.boot.Quienes_texto1_6_3}
                </label>

                <input
                  type="apellidos"
                  id="apellidos"
                  name="apellidos"
                  required
                />
                <br />
                <label htmlFor="comentario">
                  {this.state.boot.Quienes_texto1_6_4}
                </label>

                <input type="text" id="comentario" name="comentario" />
                <button className="boton botonEnviarMensaje">
                  <span>{this.state.boot.Quienes_texto1_5}</span>
                </button>
              </form>
            </div>
          </div>
        </section>
        <div className="contenedorBotonesMovil">
          <Modal
            txt={this.state.boot.nav}
            tipo="1"
            Texto={this.state.boot.nav.cuenta}
          />
          <Modal
            txt={this.state.boot.nav}
            tipo="2"
            Texto={this.state.boot.nav.sesion}
          />
        </div>
        <Footer boot={this.state.boot} />
      </>
    );
  }
}

export default Quienes;
